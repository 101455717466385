
import type { BusinessRequestDto, BusinessResponseDto, CreateEntityCompleteDto, EntityCreateDto, EntityLabelDto, EntityResponseDto, LoanDto, LoanEntityDto, RealEstateRequestDto } from 'src/backend';
import { SherpaEntityWithInformation } from 'src/slices/entity';

import { baseApi } from './baseApi';

export const entityApi = baseApi.enhanceEndpoints({ addTagTypes: ['BasicLoanDto', 'EntityResponseDto', 'LoanDto', 'LoanEntityDto'] }).injectEndpoints({
    endpoints: (build) => ({
        addLabelsToEntity: build.mutation<LoanDto, EntityLabelDto>({
            query: (data) => ({
                url: "/v2/loanmanager/entityLabel",
                method: 'POST',
                data
            }),
        }),
        postUpSomeRealEstateInformation: build.mutation<LoanDto, { entityId: string, information: RealEstateRequestDto }>({
            query: (data) => ({
                url: `/v2/information/realEstate/${data.entityId}`,
                method: 'POST',
                data: data.information
            }),
        }),
        postUpSomeBusinessEntityInformation: build.mutation<LoanDto, { entityId: string, information: BusinessRequestDto }>({
            query: (data) => ({
                url: `/v2/information/business/${data.entityId}`,
                method: 'POST',
                data: data.information
            }),
        }),
        getBusinessEntityInformation: build.query<BusinessResponseDto, { entityId: string }>({
            query: (data) => ({
                url: `/v2/information/business/${data.entityId}`,
                method: 'GET',
            }),
        }),
        createEntity: build.mutation<LoanDto, CreateEntityCompleteDto>({
            query: (data) => ({
                url: `/v2/loanmanager/createEntity`,
                method: 'POST',
                data
            }),
            invalidatesTags: [
                { type: 'BasicLoanDto', id: 'LIST' },
                { type: 'LoanDto', id: 'LIST' },
                { type: 'EntityResponseDto', id: 'LIST' }
            ]
        }),
        removeEntityFromLoan: build.mutation<LoanDto, { loanId: string, entityId: string }>({
            query: (data) => ({
                url: `/v2/loanmanager/${data.loanId}/entity/${data.entityId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'BasicLoanDto', id: 'LIST' },
                { type: 'EntityResponseDto', id: 'LIST' },
                { type: 'LoanDto', id: 'LIST' }
            ]
        }),
        getLoanEntityById: build.query<SherpaEntityWithInformation, { loanId: string, loanEntityId: string }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/loanEntity/${data.loanEntityId}`,
                method: 'GET',
            }),
            providesTags: (result) => result ? [{ type: 'LoanEntityDto', id: result.id }, { type: 'LoanEntityDto', id: 'LIST' }] : [],
            transformResponse: (loanEntity: LoanEntityDto) => ({
                ...loanEntity,
                ...loanEntity.mostRecentUserInformation,
                ...loanEntity.sherpaEntity,
                ...loanEntity.assetEntityCustomizations,
                id: loanEntity.id,
                label: loanEntity.label,
                customizations: loanEntity.assetEntityCustomizations,
                templateId: loanEntity.templateId,
                templateName: loanEntity.templateName,
            }),
        }),
        getEntitiesMissingOnLoan: build.query<EntityResponseDto[], { loanId: string }>({
            query: (data) => ({
                url: `/v2/loanmanager/${data.loanId}/lenderEntityList`,
                method: 'GET',
            }),
            providesTags: () => [{ type: 'EntityResponseDto', id: 'LIST' }],
        }),
        createBusinesses: build.mutation<EntityResponseDto, EntityCreateDto>({
            query: (data) => ({
                url: `/v2/entities/businesses`,
                method: 'POST',
                data: data

            }),
            invalidatesTags: [
                { type: 'BasicLoanDto', id: 'LIST' },
                { type: 'LoanDto', id: 'LIST' },
                { type: 'EntityResponseDto', id: 'LIST' }
            ]
        }),
    }),
    overrideExisting: true,
})

export const {
    useCreateBusinessesMutation,
    useLazyGetBusinessEntityInformationQuery,
    useGetBusinessEntityInformationQuery,
    useGetEntitiesMissingOnLoanQuery,
    useCreateEntityMutation,
    useRemoveEntityFromLoanMutation,
    useAddLabelsToEntityMutation,
    usePostUpSomeRealEstateInformationMutation,
    usePostUpSomeBusinessEntityInformationMutation,
    useGetLoanEntityByIdQuery
} = entityApi;



